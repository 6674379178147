import React, { useContext } from "react";
import { formatDate } from "../config";

export const MessageComponent = ({ message, declaration }) => {
  let user_sent = message.sender_id == declaration.org_id;
  let border = user_sent ? "border-info" : "";
  return (
    <div
      className={"chat-message mb-3" + (user_sent ? "pe-5 me-5" : "ps-5 ms-5")}
    >
      <div
        className={
          `chat-message-content bg-white px-2 py-2 rounded border ` + border
        }
      >
        {message.content_type == "text" ? (
          message.content
        ) : (
          <img src={message.content} />
        )}
      </div>
      <small className="text-muted">
        {declaration.org_id == message.sender_id
          ? declaration.org_name
          : declaration.analytics_name}
        - {formatDate(message.created_at)}
      </small>
    </div>
  );
};
