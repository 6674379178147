import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";
import { TitleComponent } from "../../components/TitleComponent";
import { FireToast } from "../../config";
import { Api } from "../../api";
import { users_type } from "../../data/data";

export const EditUser = () => {
  let [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    status: "active",
  });
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);
  let [sending, setSending] = useState(false);
  let { id } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    init();

    return () => {};
  }, []);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`users/${id}`)
      .then((result) => {
        setUser(result.user);
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  let submit = (e) => {
    e.preventDefault();
    setSending(true);

    let userBody = {
      name: user.name,
      email: user.email,
      status: user.status,
    };
    if (user.password) {
      console.log("password", user.password);
      userBody.password = user.password;
    }
    Api.put(`users/${user.id}`, {
      user: userBody,
    })
      .then((result) => {
        setSending(false);
        if (result.status == "ok") {
          FireToast("Les informations de l'utilisateur ont été modifiées");
          navigate(`/users/show/${user.id}`, { replace: true });
        } else {
          FireToast(result.message, "error");
        }
      })
      .catch((err) => {
        setSending(false);
        FireToast("Une erreur s'est produite, veuillez réessayer.", "error");
      });
  };

  let onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      <TitleComponent text="Modifier l'utilisateur" />
      <LoadingComponent
        loading={loading}
        error={error}
        onRetry={init}
        isTable={false}
      >
        <form
          onSubmit={submit}
          action=""
          method="post"
          className="col-md-6 mx-auto p-3"
        >
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Nom
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Nom"
              value={user.name}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              E-mail
            </label>
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              placeholder="E-mail"
              value={user.email}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Mot de passe
            </label>
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              placeholder="Mot de passe"
              value={user.password}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="status" className="form-label">
              Statut de l'utilisateur
            </label>
            <select
              className="form-select"
              id="status"
              name="status"
              value={user.status}
              onChange={onChange}
            >
              <option value="disable">Bloquer</option>
              <option value="active">Activer</option>
            </select>
          </div>
          <button
            type="submit"
            className="btn btn-primary text-center"
            disabled={sending}
          >
            Mettre à jour
          </button>
        </form>
      </LoadingComponent>
    </>
  );
};
