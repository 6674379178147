import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import logo from "../logo.png";
import moment from "moment";
import { UserContext } from "../store";

export const SidebarComponent = () => {
  let {
    user,
    counter: { tips },
  } = useContext(UserContext);

  return (
    <div className="sidebar sidebar-dark sidebar-fixed" id="sidebar" style={{}}>
      <div className="sidebar-brand d-none d-md-flex py-3 mb-3">
        <img src={logo} className="sidebar-brand-full" width="230" alt="" />
      </div>
      <ul className="sidebar-nav" data-coreui="navigation" data-simplebar>
        {["manager", "analytics"].includes(user.role) ? (
          <li className="nav-item">
            <NavLink className="nav-link px-1" to="/dashbaord">
              <i className="fa fa-line-chart nav-icon"></i> Tableau de bord
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        {user.role === "org" ? (
          <li className="nav-item">
            <NavLink className="nav-link px-1" end to="/declarations/create">
              <i className="fa fa-plus nav-icon"></i> Ajouter une déclaration
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        {user.role !== "admin" ? (
          <li className="nav-item">
            <NavLink className="nav-link px-1" end to="/declarations">
              <i className="fa fa-database nav-icon"></i> Les déclarations
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        {["admin", "manager"].includes(user.role) ? (
          <li className="nav-item">
            <NavLink className="nav-link px-1" to="/users">
              <i className="fa fa-users nav-icon"></i> Les utilisateurs
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        {user.role === "analytics" ? (
          <li className="nav-item">
            <NavLink className="nav-link px-1" to="/query">
              <i className="fa fa-search nav-icon"></i> Requête
            </NavLink>
          </li>
        ) : (
          <></>
        )}
        {user.role === "analytics" || user.role === "org" ? (
          <li className="nav-item">
            <NavLink className="nav-link px-1" to="/updates">
              <i className="fa fa-clipboard-list nav-icon"></i>
              {tips > 0 ? (
                <span className="badge me-1 bg-danger">
                  {tips > 10 ? "9+" : tips}
                </span>
              ) : (
                <></>
              )}
              <span>Mises à jour et astuces</span>
            </NavLink>
          </li>
        ) : (
          <></>
        )}
      </ul>
      {/* eae9e9 */}
      <small className="text-center d-block pb-2" style={{ color: "#959595" }}>
        UMEF © {moment().format("YYYY")}. Tous droits réservés
      </small>
    </div>
  );
};
