import React, { useEffect, useState } from "react";
import { FireToast } from "../../../config";
import { Api } from "../../../api";
import { SectionWrapper } from "./SectionWrapper";
import {
  updateDeclarationStatuses,
  updateDeclarationStatusesColors,
} from "../../../components/DeclarationStatusComponent";

export const ManagerActions = ({ declaration, user, onUpdateDeclaration }) => {
  let [managernewReport, setManagerNewReport] = useState("");
  let [newStatus, setNewStatus] = useState("");
  let [alertOrg, setAlertOrg] = useState(true);
  let [saving, setSaving] = useState(false);

  useEffect(() => {
    return () => {
      try {
        const myModalAlternative = new window.coreui.Modal("#managerMessage");
        myModalAlternative.hide();
      } catch (err) {}
    };
  }, []);

  if (user.role !== "manager" || declaration.status !== "review") {
    if (!declaration.manager_result || user.role !== "analytics") return <></>;

    return (
      <SectionWrapper
        name="manager-report"
        show={true}
        titleComponent={<h2 className="m-0 py-2">Décision de la direction</h2>}
      >
        {declaration.manager_result}
      </SectionWrapper>
    );
  }

  let complete = async () => {
    if (!newStatus || !managernewReport) {
      const myModalAlternative = new window.coreui.Modal("#managerMessage");
      myModalAlternative.hide();
      FireToast(
        "Veuillez entrer un message dans le champ de saisie pour continuer.",
        "error"
      );
      return;
    }

    setSaving(true);

    try {
      let result = await Api.post(`declarations/${declaration.id}/complete`, {
        status: newStatus,
        report: managernewReport,
        alert_org: alertOrg ? "yes" : "no",
      });

      if (result.status === "ok") {
        FireToast("Les données ont été enregistrées avec succès", "success");

        onUpdateDeclaration({
          ...declaration,
          status: newStatus,
          manager_result: managernewReport,
        });
      } else {
        FireToast(
          "Une erreur est survenue, veuillez réessayer plus tard.",
          "error"
        );
      }
    } catch (err) {
      console.log("error", err);
      FireToast(
        "Une erreur est survenue, veuillez réessayer plus tard.",
        "error"
      );
    }
    setSaving(false);
  };

  return (
    <>
      <div className="mt-5">
        <div className="d-flex col-md-8 mx-auto align-items-center justify-content-between">
          {Object.keys(updateDeclarationStatuses).map((item) => (
            <div className="m-1" key={item}>
              <button
                className={
                  "btn btn-lg btn-block accordion-body text-white shadow-sm " +
                  updateDeclarationStatusesColors[item]
                }
                onClick={(e) => {
                  setNewStatus(item);
                }}
                disabled={saving}
                data-coreui-toggle="modal"
                data-coreui-target="#managerMessage"
              >
                {updateDeclarationStatuses[item]}
              </button>
            </div>
          ))}
        </div>

        <div
          className="modal fade"
          id="managerMessage"
          tabIndex="-1"
          aria-labelledby="managerMessageLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  data-coreui-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <textarea
                  id={`report`}
                  name={`report`}
                  className="form-control mb-2"
                  value={managernewReport}
                  placeholder="Rédigez votre rapport..."
                  onChange={(e) => setManagerNewReport(e.target.value)}
                  rows={5}
                />
                {newStatus !== "process" ? (
                  <>
                    <br />
                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="alert-org"
                        value={alertOrg ? "yes" : "no"}
                        checked={alertOrg}
                        onChange={(e) => setAlertOrg(e.target.checked)}
                      />
                      <label class="form-check-label" htmlFor="alert-org">
                        Alerter la banque sur le statut de la déclaration
                      </label>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  onClick={complete}
                  className="btn btn-primary"
                  data-coreui-dismiss="modal"
                >
                  Confirmer
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-coreui-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
