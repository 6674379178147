import React, { createContext } from "react";

const userStoreDefault = {
  loading: false,
  logginout: false,
  user: null,
  isAuthenticated: false,
  login: (user, token) => {},
  logout: () => {},
  setCounter: () => {},
  counter: {
    messages: [],
    tips: 0,
  },
};
export const UserContext = createContext(userStoreDefault);

export const UserProvider = ({ children, value }) => (
  <UserContext.Provider value={value}>{children}</UserContext.Provider>
);
