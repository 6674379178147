import React, { useContext } from "react";
import userAvatar from "../user.png";
import logo from "../logo.png";
import { Link } from "react-router-dom";
import { UserContext } from "../store";
import Swal from "sweetalert2";

export const HeaderComponent = () => {
  let {
    logout,
    logginout,
    user,
    counter: { messages },
  } = useContext(UserContext);

  let toggleSidebar = () => {
    let elem = document.querySelector("#sidebar");
    if (
      (window.innerWidth <= 768 && !elem.classList.contains("show")) ||
      elem.classList.contains("hide")
    ) {
      elem.classList.remove("hide");
      if (window.innerWidth <= 768) {
        elem.classList.add("show");
        document.body.classList.add("body-sidebar-show");
        elem.insertAdjacentHTML(
          "afterend",
          '<div className="sidebar-backdrop fade show"></div>'
        );
        document
          .querySelector(".sidebar-backdrop")
          ?.addEventListener("click", hideSidebar);
      }
    } else {
      hideSidebar();
    }
  };

  let hideSidebar = () => {
    let elem = document.querySelector("#sidebar");
    let backdropElem = document.querySelector(".sidebar-backdrop");
    elem.classList.add("hide");
    elem.classList.remove("show");
    document.body.classList.remove("body-sidebar-show");
    backdropElem?.removeEventListener("click", hideSidebar);
    backdropElem?.remove();
  };

  let logoutConfirm = async (e) => {
    e.preventDefault();
    if (logginout) return;

    let confirmation = await Swal.fire({
      title: "Vous allez être déconnecté du tableau de bord?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    });
    if (!confirmation.isConfirmed) return;

    logout();
  };

  return (
    <header className="header header-sticky mb-4">
      <div className="container-fluid">
        <button
          className="header-toggler px-md-0 me-md-3"
          type="button"
          onClick={toggleSidebar}
        >
          <i className="fa fa-th-large icon icon-lg"></i>
        </button>
        <a className="header-brand d-md-none" href="#">
          <img src={logo} alt="" width="45" />
        </a>
        <ul className="header-nav d-none d-md-flex"></ul>
        <ul className="header-nav ms-auto me-5">
          {/* <li className="nav-item">
            <a className="nav-link" href="#!">
              {messages.length > 0 ? (
                <span className="badge me-1 bg-info">{messages.length}</span>
              ) : (
                <></>
              )}
              <i className="fa fa-bell icon icon-lg"></i>
            </a>
          </li> */}

          <li className="nav-item dropdown">
            <a
              className="nav-link show"
              data-coreui-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="true"
            >
              <i className="fa  fa-bell icon icon-lg"></i>
              {messages.length > 0 ? (
                <span className="position-absolute top-0 end-75 translate-middle badge rounded-pill bg-danger">
                  {messages.length}
                </span>
              ) : (
                <></>
              )}
            </a>
            <div
              className="dropdown-menu dropdown-menu-end dropdown-menu-md pt-0"
              style={{
                minWidth: 400,
                width: 400,
              }}
              data-popper-placement="bottom-start"
            >
              <div
                className="dropdown-header bg-body-tertiary text-body-secondary fw-semibold rounded-top mb-2"
                data-coreui-i18n="messagesCounter, { 'counter': 7 }"
              >
                Vous avez {messages.length} messages
              </div>
              {messages.map((message) => (
                <Link
                  className="dropdown-item"
                  to={
                    user.role === "org"
                      ? `/declarations/create/${message.chatable_id}#discussions`
                      : `/declarations/show/${message.chatable_id}#discussions`
                  }
                >
                  <div className="d-flex">
                    <div className="avatar flex-shrink-0 my-3 me-3 bg-warning text-white">
                      {message.count}{" "}
                      <span className="avatar-status bg-success"></span>
                    </div>
                    <div className="message text-wrap">
                      <div className="fw-semibold">
                        Vous avez des messages non lus concernant la déclaration
                        numéro : #{message.chatable_id}
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </li>
        </ul>
        <ul className="header-nav me-3">
          <li className="nav-item dropdown">
            <a
              className="nav-link py-0"
              data-coreui-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="avatar avatar-md">
                <img className="avatar-img" src={userAvatar} alt={user.name} />
                <span className="ms-2">{user.name}</span>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end pt-0">
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="/change_password">
                <i className="fa fa-lock icon me-2"></i>
                <span>Changer le mot de passe</span>
              </Link>
              <a className="dropdown-item" href="#!" onClick={logoutConfirm}>
                <i className="fa fa-sign-out icon me-2"></i>
                <span>Déconnexion</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </header>
  );
};
