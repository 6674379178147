import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TitleComponent } from "../components/TitleComponent";
import { FireToast } from "../config";
import { Api } from "../api";
import { users_type } from "../data/data";

export const ChangePasswordPage = () => {
  let [user, setUser] = useState({
    password: "",
    new_password: "",
    confirm_password: "",
  });
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [sending, setSending] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    return () => {};
  }, []);

  let submit = (e) => {
    e.preventDefault();

    if (!user.password) {
      FireToast("Entrez le mot de passe actuel", "error");
      return;
    }
    if (!user.new_password) {
      FireToast("Entrez le nouveau mot de passe", "error");
      return;
    }
    if (!user.confirm_password) {
      FireToast("Veuillez confirmer le mot de passe", "error");
      return;
    }
    if (user.confirm_password !== user.new_password) {
      FireToast("Les mots de passe ne correspondent pas.", "error");
      return;
    }

    setSending(true);
    Api.post(`authentication/change_password`, {
      password: user.password,
      new_password: user.new_password,
      confirm_password: user.confirm_password,
    })
      .then((result) => {
        setSending(false);
        if (result.status == "ok") {
          FireToast("Les informations de l'utilisateur ont été ajoutées.");
          navigate(`/`, { replace: true });
        } else if (result.message == "invalid_password") {
          FireToast("Le mot de passe actuel est incorrect!", "error");
        } else {
          FireToast("Une erreur s'est produite, veuillez réessayer.", "error");
        }
      })
      .catch((err) => {
        setSending(false);
        FireToast("Une erreur s'est produite, veuillez réessayer.", "error");
      });
  };

  let onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <>
      <TitleComponent text="Changer le mot de passe" />

      <form
        onSubmit={submit}
        action=""
        method="post"
        className="col-md-6 mx-auto p-3"
      >
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Mot de passe actuel
          </label>
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            placeholder="Mot de passe actuel"
            value={user.password}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Nouveau mot de passe
          </label>
          <input
            type="password"
            className="form-control"
            id="new_password"
            name="new_password"
            placeholder="Nouveau mot de passe"
            value={user.new_password}
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Confirmer le nouveau mot de passe
          </label>
          <input
            type="password"
            className="form-control"
            id="confirm_password"
            name="confirm_password"
            placeholder="Confirmer le nouveau mot de passe"
            value={user.confirm_password}
            onChange={onChange}
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary text-center"
            disabled={sending}
          >
            Modifier
          </button>
        </div>
      </form>
    </>
  );
};
