import axios from "axios";
import { api_url, basename } from "./config";

export class Api {
  static get(url) {
    return new Promise((resolive, reject) =>
      axios
        .get(`${api_url}/${url}`, {
          headers: this.headers,
        })
        .then((result) => resolive(result.data))
        .catch((error) => {
          this.reject_error(error);
          reject(error);
        })
    );
  }

  static post(url, body = {}, defaultHeaders = {}) {
    return new Promise((resolive, reject) =>
      axios
        .post(`${api_url}/${url}`, body, {
          headers: { ...this.headers, ...defaultHeaders },
        })
        .then((result) => resolive(result.data))
        .catch((error) => {
          this.reject_error(error);
          reject(error);
        })
    );
  }

  static put(url, body = {}) {
    return new Promise((resolive, reject) =>
      axios
        .put(`${api_url}/${url}`, body, {
          headers: this.headers,
        })
        .then((result) => resolive(result.data))
        .catch((error) => {
          this.reject_error(error);
          reject(error);
        })
    );
  }

  static delete(url) {
    return new Promise((resolive, reject) =>
      axios
        .delete(`${api_url}/${url}`, {
          headers: this.headers,
        })
        .then((result) => resolive(result.data))
        .catch((error) => {
          this.reject_error(error);
          reject(error);
        })
    );
  }

  static reject_error(error) {
    // console.log("error1", error);
    if (error.response.status === 401 || error.response.status === 404) {
      window.location.href = `/${basename}`;
    }
  }

  static get token() {
    return localStorage.getItem("user_token");
  }

  static get headers() {
    return {
      "Authorization-x": `bearer ${this.token}`,
    };
  }
}
