import React from "react";

export const SearchComponent = ({ query, onChange, space = true }) => {
  return (
    <div className={(space ? "my-2 p-2 " : "") + "search-bar"}>
      <div className="d-flex">
        <input
          type="text"
          placeholder="Recherche..."
          className="form-control rounded-1"
          value={query}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
