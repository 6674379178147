import React from "react";
import {
  BtnBold,
  BtnItalic,
  BtnBulletList,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  BtnRedo,
  createButton,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";

const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");

export default function CustomEditor({ value, onChange, placeholder }) {
  return (
    <EditorProvider>
      <Editor
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        containerProps={{ style: { minHeight: "250px", maxHeight: 500 } }}
      >
        <Toolbar>
          {/* <BtnAlignCenter /> */}
          <BtnBold />
          <BtnItalic />
          <BtnStrikeThrough />
          <BtnUnderline />
          <BtnUnderline />
          <BtnBulletList />
          <BtnNumberedList />
          <BtnUndo />
          <BtnRedo />
        </Toolbar>
      </Editor>
    </EditorProvider>
  );
}
