import React from "react";
import { Outlet } from "react-router-dom";

export const DeclarationsPage = () => {
  return (
    <div className="">
      <Outlet />
    </div>
  );
};
