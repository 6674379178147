import React from "react";

export const ChatFormComponent = ({ message, setMessage, sending, onSend }) => {
  return (
    <>
      <div className="chat-header px-2 py-2 mb-3">
        <textarea
          className="form-control me-2"
          name="message"
          id="message"
          rows="5"
          placeholder="Votre message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <div className="align-items-start mt-2 row">
          <div className="">
            <button
              className="btn btn-primary"
              disabled={sending}
              onClick={onSend}
            >
              <span className="fa fa-send mx-1"></span>
              <span className="d-md-inline-block d-none px-1">Envoyer</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
