import React, { useEffect, useState } from "react";
import {
  defaultSuspectAccount,
  formFieldsFr,
  sectionsFr,
} from "../../../data/data";
import { DataInput } from "../../../components/DataInput";
import { clearDebounce, debounce, FireToast } from "../../../config";
import { Uploader } from "../../../components/Uploader";
import { FileComponent } from "../../../components/FileComponent";
import { Api } from "../../../api";
import { useNavigate } from "react-router-dom";
import { SectionWrapper } from "./SectionWrapper";
import Swal from "sweetalert2";

export const DeclarationForm = ({
  readOnly,
  post,
  savedPost,
  user,
  setFiles,
  files,
  onUpdateDeclaration,
  setPost,
}) => {
  let [saving, setSaving] = useState(false);
  let [isDirty, setIsDirty] = useState(false);
  let [suspectAccounts, setSuspectAccounts] = useState(
    post.suspect_accounts?.length > 0
      ? post.suspect_accounts
      : [{ ...defaultSuspectAccount }]
  );

  const navigate = useNavigate();

  // if (user.role === "manager") return <></>;

  useEffect(() => {
    if (isDirty) {
      debouncedSave(true);
    } else {
      clearDebounce();
    }
    return () => {
      clearDebounce();
    };
  }, [post, suspectAccounts, isDirty]);

  let savePost = async (autoSave = false) => {
    setSaving(true);

    try {
      let result;
      if (post.id) {
        result = await Api.put(`declarations/${post.id}`, {
          declaration: post,
          suspect_accounts: suspectAccounts,
          attachments_ids: files.map((f) => f.id),
        });
      } else {
        result = await Api.post(`declarations`, {
          declaration: post,
          suspect_accounts: suspectAccounts,
          attachments_ids: files.map((f) => f.id),
        });
      }

      if (result.status === "ok") {
        if (!autoSave) {
          FireToast("Les données ont été enregistrées avec succès", "success");
        }
        if (!post.id) {
          onUpdateDeclaration({ ...post, ...result.declaration });
          navigate(`/declarations/create/${result.declaration.id}`);
        } else {
          setFiles([...result.attachments]);
        }
        setIsDirty(false);
      } else {
        if (!autoSave) {
          FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
        }
      }
    } catch (err) {
      console.log("error", err);
      if (!autoSave) {
        FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
      }
    }
    setSaving(false);
  };

  const debouncedSave = debounce(savePost, 8000);

  let submitPost = async () => {
    let confirmation = await Swal.fire({
      title: "Êtes-vous sûr ?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    });
    if (!confirmation.isConfirmed) return;

    if (isDirty) {
      await savePost(true);
    }

    setSaving(true);

    try {
      let result = await Api.get(`declarations/${post.id}/submit`);

      if (result.status === "ok") {
        FireToast("Les données ont été envoyées à UMEF.", "success");
        onUpdateDeclaration({ ...post, status: result.declaration_status });
        setFiles([...result.attachments]);
      } else {
        FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
      }
    } catch (err) {
      console.log("error", err);
      FireToast(
        "Une erreur est survenue, veuillez réessayer plus tard.",
        "error"
      );
    }
    setSaving(false);
  };

  let onFieldChanged = (keyName, value) => {
    setPost({
      ...post,
      [keyName]: value,
    });
    setIsDirty(true);
  };

  let onSuspectAccountFieldChanged = (keyName, value, index) => {
    const updatedPersons = suspectAccounts.map((person, i) => {
      if (i === index) {
        return { ...person, [keyName]: value };
      }
      return person;
    });
    setSuspectAccounts(updatedPersons);
    setIsDirty(true);
  };

  let onFileDeleted = (file) => {
    let item = files.find((item) => item.id === file.id);
    let index = files.indexOf(item);
    let oldFiles = [...files];
    if (index !== -1) {
      oldFiles.splice(index, 1);
    }
    setFiles([...oldFiles]);
  };

  let myFiles = files.filter((f) => f.user_id === savedPost.org_id);

  // reporting_entity,declaration_responsible_person,suspicion_declaration
  return (
    <SectionWrapper
      name="form"
      show={
        (user.role === "analytics" && post.status === "submitted") ||
        user.role === "org"
      }
      titleComponent={
        <div
          style={{ width: "auto" }}
          className="avatar avatar-xl justify-content-center align-items-center"
        >
          {/* <img
            className="avatar-img me-2"
            src={logo}
            style={{ width: "70px", maxWidth: 70, height: "auto" }}
          /> */}
          <span>{post.org_name}</span>
        </div>
      }
    >
      <ol className="p-0" type="I">
        {Object.keys(sectionsFr).map((sectionKey) =>
          (readOnly && sectionKey === "documents_to_provide") ||
          (user.role !== "org" &&
            savedPost.status === "submitted" &&
            ![
              "reporting_entity",
              "declaration_responsible_person",
              "suspicion_declaration",
            ].includes(sectionKey)) ? (
            <></>
          ) : (
            <div className="card border-0 mt-3" key={sectionKey}>
              <div className="card-body p-0">
                <div className="form-group mb-0">
                  <li className="ms-4">
                    <div
                      htmlFor="name"
                      className="font-weight-bold bg-light mb-2 p-2 rounded d-inline-block"
                    >
                      {typeof sectionsFr[sectionKey] === "string"
                        ? sectionsFr[sectionKey]
                        : sectionsFr[sectionKey].name}
                    </div>
                  </li>

                  {sectionKey === "suspect_accounts_info" ? (
                    <ol className="p-0">
                      {suspectAccounts.map((account, index) => (
                        <div className="card border-0" key={index}>
                          <div className="card-body">
                            <div className="form-group mb-0">
                              <li className="ms-3"></li>
                              <table className="table table-bordered table-condensed">
                                <tbody>
                                  {Object.keys(account).map((itemKey) =>
                                    itemKey ===
                                    "account_study_period_end_date" ? (
                                      <></>
                                    ) : (
                                      <DataInput
                                        key={itemKey}
                                        itemKey={itemKey}
                                        label={
                                          formFieldsFr[sectionKey][itemKey]
                                        }
                                        post={{
                                          ...account,
                                          status: post.status,
                                        }}
                                        savedPost={account}
                                        onFieldChanged={(...args) =>
                                          onSuspectAccountFieldChanged(
                                            ...args,
                                            index
                                          )
                                        }
                                        defaultReadOnly={readOnly}
                                      />
                                    )
                                  )}
                                </tbody>
                              </table>
                              {suspectAccounts.length > 1 ? (
                                <div className="text-end">
                                  <button
                                    className="btn btn-danger text-white"
                                    onClick={(e) => {
                                      const updatedAccounts =
                                        suspectAccounts.filter(
                                          (_, i) => i !== index
                                        );
                                      setSuspectAccounts(updatedAccounts);
                                      setIsDirty(true);
                                    }}
                                  >
                                    <i className="fa fa-minus"></i>
                                  </button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </ol>
                  ) : typeof sectionsFr[sectionKey] !== "string" ? (
                    <ol className="p-0">
                      {Object.keys(sectionsFr[sectionKey].options).map(
                        (subSectionKey, index) => (
                          <div className="card border-0" key={subSectionKey}>
                            <div className="card-body">
                              <div className="form-group mb-0">
                                <li className="ms-3">
                                  <div
                                    htmlFor="name"
                                    className="font-weight-bold bg-light mb-2 p-2 rounded d-inline-block"
                                  >
                                    {
                                      sectionsFr[sectionKey].options[
                                        subSectionKey
                                      ]
                                    }
                                  </div>
                                </li>
                                <table className="table table-bordered table-condensed">
                                  <tbody>
                                    {Object.keys(
                                      formFieldsFr[sectionKey][subSectionKey]
                                    ).map((itemKey) => (
                                      <DataInput
                                        key={itemKey}
                                        itemKey={itemKey}
                                        label={
                                          formFieldsFr[sectionKey][
                                            subSectionKey
                                          ][itemKey]
                                        }
                                        post={post}
                                        savedPost={savedPost}
                                        onFieldChanged={onFieldChanged}
                                        defaultReadOnly={readOnly}
                                      />
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </ol>
                  ) : (
                    <table className="table table-bordered table-condensed">
                      <tbody>
                        {Object.keys(formFieldsFr[sectionKey]).map(
                          (itemKey) => (
                            <DataInput
                              key={itemKey}
                              itemKey={itemKey}
                              label={formFieldsFr[sectionKey][itemKey]}
                              post={post}
                              savedPost={savedPost}
                              onFieldChanged={onFieldChanged}
                              defaultReadOnly={readOnly}
                            />
                          )
                        )}
                      </tbody>
                    </table>
                  )}
                  {sectionKey === "suspect_accounts_info" ? (
                    <div className="text-start mb-3">
                      <button
                        className="btn btn-success"
                        onClick={(e) => {
                          setSuspectAccounts([
                            ...suspectAccounts,
                            { ...defaultSuspectAccount },
                          ]);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </ol>
      <Uploader
        readOnly={readOnly}
        onFilesChanged={(newFiles) => {
          setFiles([...files, ...newFiles]);
          setIsDirty(true);
        }}
      />
      {!saving ? (
        <table className="table bg-white table-bordered table-condensed my-2">
          <tbody>
            {myFiles.map((file) => (
              <FileComponent
                key={file.id || file.name}
                defaultFile={file}
                declaration={savedPost}
                onDeleted={onFileDeleted}
                readOnly={readOnly}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}

      {readOnly ? (
        <></>
      ) : (
        <div className="mt-5">
          <button
            className="btn btn-lg btn-info text-white  shadow-none mx-2"
            onClick={(e) => savePost()}
            disabled={saving}
          >
            Enregistrer
          </button>

          {!!post.id && post.status === "draft" ? (
            <button
              className="btn btn-lg btn-primary  shadow-none mx-2"
              onClick={submitPost}
              disabled={saving}
            >
              Envoyer les données à UMEF
            </button>
          ) : (
            <></>
          )}
        </div>
      )}
    </SectionWrapper>
  );
};
