import React, { useContext, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { UserContext } from "../store";

export const Uploader = ({ onFilesChanged, readOnly }) => {
  let { user } = useContext(UserContext);

  if (readOnly) return <></>;

  let onDrop = (files) => {
    if (!files) return;
    // console.log(files);
    let updatedFiles = files.map((file) => ({
      name: file.name,
      submitted: false,
      user_id: user.id,
      raw: file,
    }));
    onFilesChanged(updatedFiles);
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls", ".csv"],
      "text/csv": [".csv"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
      "application/xml": [".xml"],
      "application/ofx": [".ofx"],
      "application/mt940": [".mt940"],
      "application/qfx": [".qfx"],
      "image/*": [".jpeg", ".png", ".jpg", ".gif", ".bmp"],
      "text/plain": [],
    },
    multiple: true,
    maxFiles: 8,
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className="text-center">
      <div className="row">
        <div className="col-12 py-2"></div>
        <div className="col-12 mx-auto">
          <div {...getRootProps({ className: "dropzone shadow-sm", style })}>
            <input {...getInputProps()} />
            {isDragAccept && <p>Tous les fichiers sont acceptés</p>}
            {isDragReject && (
              <p>Certains fichiers ne sont pas pris en charge</p>
            )}
            {!isDragActive && (
              <p>Faites glisser et déposez les fichiers ici...</p>
            )}
            <em>
              8 fichiers est le nombre maximum de fichiers que vous pouvez
              déposer ici en une seule fois.
            </em>
            <em>
              {`.xlsx,.xls,.csv,.docx,.doc,.ofx,.xml,.mt940,.qfx,.pdf,.jpg,.jpeg,.png,.gif,.bmp,.tif,.tiff`.toUpperCase()}
            </em>
          </div>
        </div>
        <div className="col-12 py-3"></div>
      </div>
    </div>
  );
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgb(137 137 137)",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "rgb(108 108 108)",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};
