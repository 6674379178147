import React, { useContext, useState } from "react";
import { TitleComponent } from "../components/TitleComponent";
import { FireToast } from "../config";
import { UserContext } from "../store";
import { Api } from "../api";
import logo from "../logo.png";
import moment from "moment";

export const LoginPage = () => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [loading, setLoading] = useState(false);
  let { setUser, login } = useContext(UserContext);

  let submit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      FireToast("Veuillez entrer l'e-mail et le mot de passe.", "error");
      return;
    }
    setLoading(true);
    Api.post(`authentication/login`, {
      email: email,
      password: password,
    })
      .then((result) => {
        if (result.status == "ok") {
          FireToast("Vous êtes connecté.");
          login(result.user, result.token);
        } else {
          FireToast("Informations de connexion incorrectes.", "error");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        FireToast("Une erreur est survenue, veuillez réessayer.", "error");
      });
  };

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center">
      <div className="row w-100" style={{ maxWidth: "1300px" }}>
        <div
          className="col-lg-6 d-none d-lg-flex align-items-center justify-content-center"
          style={{
            background:
              "linear-gradient(135deg, rgba(255, 99, 71, 0.2), rgba(144, 238, 144, 0.2), rgba(255, 255, 224, 0.2))",
            borderTopLeftRadius: "35px",
            borderBottomLeftRadius: "35px",
            padding: "4rem",
            color: "#fff",
          }}
        >
          <div
            className="text-center text-black-50"
            style={{ maxWidth: "400px" }}
          >
            <img
              src={logo}
              className="rounded-3"
              alt="Logo de l'UMEF"
              style={{ width: "350px", marginBottom: "30px" }}
            />
            <h1
              style={{
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: "2.8rem",
                letterSpacing: "2px",
              }}
            >
              Bienvenue sur le Portail UMEF
            </h1>
            <p
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.8",
                fontWeight: "300",
              }}
            >
              Accédez à votre portail et gérez efficacement vos déclarations
            </p>
          </div>
        </div>

        <div
          className="col-lg-6 d-flex align-items-center justify-content-center"
          style={{
            background: "#fff",
            borderTopRightRadius: "35px",
            borderBottomRightRadius: "35px",
            padding: "5rem",
            boxShadow: "0 20px 40px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="w-100" style={{ maxWidth: "450px" }}>
            <div className="text-center mb-5">
              <h3
                style={{
                  fontWeight: "700",
                  color: "#2d2f33",
                  marginBottom: "25px",
                  fontSize: "2.4rem",
                }}
              >
                Connexion au Portail UMEF
              </h3>
            </div>
            <form onSubmit={submit} method="post">
              <div className="form-group mb-4">
                <label
                  htmlFor="email"
                  style={{ color: "#555", fontWeight: "500", fontSize: "1rem" }}
                >
                  Adresse E-mail
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Entrez votre adresse e-mail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{
                    borderRadius: "15px",
                    border: "none",
                    padding: "16px",
                    backgroundColor: "#f7f9fc",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.05)",
                    fontSize: "1rem",
                  }}
                />
              </div>
              <div className="form-group mb-4">
                <label
                  htmlFor="password"
                  style={{ color: "#555", fontWeight: "500", fontSize: "1rem" }}
                >
                  Mot de passe
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Entrez votre mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    borderRadius: "15px",
                    border: "none",
                    padding: "16px",
                    backgroundColor: "#f7f9fc",
                    boxShadow: "0 10px 25px rgba(0, 0, 0, 0.05)",
                    fontSize: "1rem",
                  }}
                />
              </div>
              <button
                type="submit"
                className="btn btn-block"
                style={{
                  background: "linear-gradient(135deg, #4CAF50, #66BB6A)",
                  borderRadius: "15px",
                  border: "none",
                  padding: "15px 0",
                  width: "100%",
                  color: "#fff",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  transition: "transform 0.3s",
                }}
                disabled={loading}
              >
                Se Connecter
              </button>
            </form>
            <div className="text-center mt-4"></div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <small
            className="text-center d-block pb-2"
            style={{ color: "#959595" }}
          >
            UMEF © {moment().format("YYYY")}. Tous droits réservés
          </small>
        </div>
      </div>
    </div>
  );
};
