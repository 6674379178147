import React from "react";
import { formFieldsFr } from "../data/data";
import DatePicker from "react-datepicker";
import moment from "moment";
import { inputsOptions } from "../data/options_data";

import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-date-picker";
// import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";

let dateFormat = "YYYY-MM-DD HH:mm:ss";

export const DataInput = ({
  itemKey,
  label,
  post,
  onFieldChanged,
  savedPost,
  defaultReadOnly,
  children,
}) => {
  if (formFieldsFr.documents_to_provide[itemKey] !== undefined) {
    return (
      <tr className="text-center">
        <td className="text-start align-middle">{label}</td>
      </tr>
    );
  }

  let value = post[itemKey] || "";
  let updatedValue = null;
  if (value === undefined) {
    value = "";
  }

  let options = [];
  if (inputsOptions[itemKey] !== undefined) {
    options = inputsOptions[itemKey].fr;
  } else if (`${itemKey}`.includes("date") && value) {
    let momentDate = moment(value, dateFormat);
    if (momentDate.isValid()) {
      updatedValue = moment(value, dateFormat).toDate();
    }
  }

  let readOnly = defaultReadOnly;
  if (
    !readOnly &&
    !!post.status &&
    post.status !== "draft" &&
    !!savedPost[itemKey]
  ) {
    readOnly = true;
  }

  // suspicion_indications_and_reasons, suspicion_motives
  return (
    <tr className="text-center align-middle">
      <td className="text-start align-middle" style={{ width: "60%" }}>
        {label}
      </td>
      <td>
        {options.length > 0 ? (
          <>
            <select
              disabled={readOnly}
              id={`${itemKey}`}
              name={`${itemKey}`}
              className="form-select shadow-none"
              value={value}
              onChange={(e) => onFieldChanged(itemKey, e.target.value)}
            >
              {readOnly ? (
                <></>
              ) : (
                <option value="">Choisissez dans la liste</option>
              )}
              {options.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            {itemKey === "additional_actions_taken" && value === "Autres" ? (
              <div className="mt-1">
                <input
                  type="text"
                  disabled={readOnly}
                  id={"additional_actions_taken_other"}
                  name={`additional_actions_taken_other`}
                  className="form-control"
                  value={post["additional_actions_taken_other"]}
                  placeholder={readOnly ? "" : "Autres mesures prises"}
                  onChange={(e) =>
                    onFieldChanged(
                      "additional_actions_taken_other",
                      e.target.value
                    )
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </>
        ) : `${itemKey}`.includes("date") ? (
          <div className="d-flex align-items-center">
            <DatePicker
              name={`${itemKey}`}
              // showMonthYearDropdown={true}
              // showYearPicker={true}
              showYearDropdown={true}
              showMonthDropdown={true}
              // showMonthDropdown={true}
              readOnly={readOnly}
              selected={updatedValue}
              onChange={(date) => {
                let value = "";
                let momentDate = moment(date);
                if (momentDate.isValid()) {
                  value = momentDate.format(dateFormat);
                }
                onFieldChanged(itemKey, value);
              }}
              placeholderText={
                readOnly
                  ? ""
                  : itemKey === "account_study_period_start_date"
                  ? "De"
                  : "Choisissez une date"
              }
              className="form-control"
              wrapperClassName="w-100"
              dateFormat={"dd-MM-YYYY"}
            />
            {itemKey === "account_study_period_start_date" ? (
              <>
                <span className="mx-2"> - </span>
                <DatePicker
                  name={`${itemKey}`}
                  readOnly={
                    defaultReadOnly ||
                    (!!post.status &&
                      post.status !== "draft" &&
                      !!savedPost.account_study_period_end_date)
                  }
                  selected={
                    post.account_study_period_end_date
                      ? moment(
                          post.account_study_period_end_date,
                          dateFormat
                        ).toDate()
                      : null
                  }
                  onChange={(date) => {
                    let value = "";
                    let momentDate = moment(date);
                    if (momentDate.isValid()) {
                      value = momentDate.format(dateFormat);
                    }
                    onFieldChanged("account_study_period_end_date", value);
                  }}
                  placeholderText={readOnly ? "" : "À"}
                  className="form-control"
                  wrapperClassName="w-100"
                  dateFormat={"dd-MM-YYYY"}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        ) : ["suspicion_indications_and_reasons", "suspicion_motives"].includes(
            itemKey
          ) ? (
          <textarea
            rows={3}
            disabled={readOnly}
            id={`${itemKey}`}
            name={`${itemKey}`}
            className="form-control"
            value={value}
            placeholder={readOnly ? "" : label}
            onChange={(e) => onFieldChanged(itemKey, e.target.value)}
          />
        ) : (
          <input
            type="text"
            disabled={readOnly}
            id={`${itemKey}`}
            name={`${itemKey}`}
            className="form-control"
            value={value}
            placeholder={readOnly ? "" : label}
            onChange={(e) => onFieldChanged(itemKey, e.target.value)}
          />
        )}
      </td>
      {children}
    </tr>
  );
};
