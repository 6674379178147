import React, { useState } from "react";
import moment from "moment";
import { formatDate } from "../../config";
import { Api } from "../../api";
import { CountryFlag } from "../../components/CountryFlag";

export const UserSessions = ({ sessions, onLogout }) => {
  return (
    <div className=" mt-5">
      <h2 className="mb-4">Sessions de l'utilisateur</h2>
      {sessions.map((session) => (
        <UserSessionCard
          key={session.id}
          session={session}
          onLogout={onLogout}
        />
      ))}
    </div>
  );
};

const UserSessionCard = ({ session, onLogout }) => {
  let [deleting, setDelete] = useState(false);

  const formatTimeAgo = (date) => {
    return moment(date).fromNow();
  };

  let deleteSession = () => {
    if (!confirm("Vous allez être déconnecté du tableau de bord?")) return;

    setDelete(true);

    Api.delete(
      `users/${session.user_id}/delete_session?session_id=${session.id}`
    )
      .then((result) => {
        if (result.status === "ok") {
          onLogout(session);
        }
        setDelete(false);
      })
      .catch((err) => {
        setDelete(false);
      });
  };

  const {
    expiration_at,
    ip_address,
    user_agent,
    device_id,
    last_activity_at,
    active,
    country_code,
  } = session;

  let [browser, browserV, os, osV] = user_agent.split("|");
  return (
    <div className={`card mb-3 ${active ? "border-primary" : "border-dark"}`}>
      <div className="card-body p-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h6 className="card-title mb-0">{device_id}</h6>
          <span className={`badge ${active ? "bg-primary" : "bg-dark"}`}>
            {active ? "Active" : "Inactive"}
          </span>
        </div>
        <p className="card-text small mb-1">
          <strong>IP:</strong> <CountryFlag countryCode={country_code} />{" "}
          {ip_address}
        </p>
        <p className="card-text small mb-1">
          <strong>Dernière activité:</strong> {formatTimeAgo(last_activity_at)}
        </p>
        <p className="card-text small mb-1">
          <strong>Expire le:</strong> {formatDate(expiration_at)}
        </p>
        <p
          className="card-text small text-muted mb-2"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          Browser: {browser}, {browserV}
        </p>
        <p
          className="card-text small text-muted mb-2"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          OS: {os}, {osV}
        </p>
        {active && (
          <button
            className="btn btn-danger btn-sm"
            disabled={deleting}
            onClick={deleteSession}
          >
            Déconnecter
          </button>
        )}
      </div>
    </div>
  );
};
