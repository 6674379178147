import React from "react";

export const CountryFlag = ({ countryCode, size = "md" }) => {
  const getFlagEmoji = (code) => {
    if (!code) return "🏳️";

    const codePoints = code
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt());

    return String.fromCodePoint(...codePoints);
  };

  const sizeClasses = {
    sm: "text-lg",
    md: "text-2xl",
    lg: "text-4xl",
  };

  return (
    <span className="">
      <span className={`${sizeClasses[size]} leading-none`} role="img">
        {getFlagEmoji(countryCode)}
      </span>
    </span>
  );
};
