import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AppStarer } from "../app";
import { basename } from "../app/config";
// import "../bootstrap";
import coreui from "@coreui/coreui/dist/js/coreui.bundle";

document.addEventListener("DOMContentLoaded", () => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(
    <BrowserRouter basename={basename}>
      <AppStarer />
    </BrowserRouter>
  );
  // ReactDOM.render(
  //   <BrowserRouter basename={basename}>
  //     <App />
  //   </BrowserRouter>,
  //   document.querySelector("#root")
  // );
  window.coreui = coreui;
});
