import React from "react";

export const SectionWrapper = ({
  name,
  titleComponent,
  show = false,
  children,
}) => {
  return (
    <div className={`${name}-section my-5`}>
      <div className="mb-3 d-inline-block" style={{ marginLeft: -20 }}>
        <button
          className={
            "btn m-0 py-0 px-2 btn-light bg-white d-flex justify-content-center align-items-center shadow-sm " +
            (show ? "" : "collapsed")
          }
          type="button"
          data-coreui-toggle="collapse"
          data-coreui-target={`#${name}-collapse`}
          aria-expanded={show ? "true" : "false"}
          aria-controls={`${name}-collapse`}
        >
          <div className="me-3">
            <i className="fa fa-plus"></i>
            <i className="fa fa-minus"></i>
          </div>
          {titleComponent}
        </button>
      </div>
      <div className="card rounded-3 accordion-body">
        <div
          className={
            "card-body px-0 py-0 pb-5 collapse " + (show ? "show" : "")
          }
          id={`${name}-collapse`}
        >
          <button
            className="btn btn-light position-absolute close-collapse"
            type="button"
            data-coreui-toggle="collapse"
            data-coreui-target={`#${name}-collapse`}
            aria-expanded={show ? "true" : "false"}
            aria-controls={`${name}-collapse`}
          >
            <span className="mx-1 hide">Masquer</span>
            <span className="mx-1 show">Afficher</span>
            <i className="fa fa-plus"></i>
            <i className="fa fa-minus"></i>
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};
