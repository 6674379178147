import React from "react";
import { useNavigate } from "react-router-dom";

export const TitleComponent = ({
  text,
  showBackButton = true,
  onActionClick,
  actionText = "Ajouter",
  actionIcon = "fa-plus",
}) => {
  let navigate = useNavigate();
  return (
    <div className="align-items-center d-flex mb-3 p-2">
      {showBackButton && navigate.length > 0 ? (
        <button
          className="btn btn-outline-secondary text-nowrap btn-sm"
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-chevron-left"></i>
          <span className="px-1">Retour</span>
        </button>
      ) : (
        <></>
      )}
      <h2 className="m-0 ps-2 w-100">{text}</h2>
      {onActionClick ? (
        <button className="btn btn-primary text-nowrap" onClick={onActionClick}>
          <i className={"fa " + actionIcon}></i>
          {actionText ? <span className="px-1">{actionText}</span> : <></>}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
};
