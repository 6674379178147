import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { SectionWrapper } from "./SectionWrapper";

export const RelatedDeclarations = ({ declaration, user, related }) => {
  if (user.role !== "analytics" || related.length <= 0) return;

  return (
    <SectionWrapper
      name="association"
      show={false}
      titleComponent={<h2 className="m-0 py-2">Associées</h2>}
    >
      <ul class="list-group">
        {related.map((item) => (
          <li class="list-group-item">
            <Link to={`/declarations/show/${item.id}`}>
              {`${item.id}`.padStart(3, "0")}/{item.org_name}/
              {moment(item.created_at).format("YYYY")}
            </Link>
          </li>
        ))}
      </ul>
    </SectionWrapper>
  );
};
