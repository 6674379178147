import React, { useContext, useEffect, useState } from "react";
import { Api } from "../api";
import { LoadingComponent } from "../components/LoadingComponent";
import { FireToast, formatDate } from "../config";
import { UserContext } from "../store";

export const UpdatesPage = ({}) => {
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);
  let [messages, setMessages] = useState([]);
  let { user } = useContext(UserContext);

  useEffect(() => {
    init();
  }, []);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`updates`)
      .then((result) => {
        let oldMessages = result.messages || [];
        setMessages(oldMessages);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setError(true);
      });
  };

  let onNewMessage = (message) => {
    let oldMessages = [...(messages || []), message];
    setMessages(oldMessages);
  };

  return (
    <div id="updates">
      {user.role === "analytics" ? (
        <MessageFormComponent onSend={onNewMessage} />
      ) : (
        <></>
      )}

      <LoadingComponent
        loading={loading}
        error={error}
        onRetry={init}
        isTable={false}
        hasData={messages.length > 0}
      >
        <div className="p-3">
          {messages.map((message) => (
            <MessageComponent key={message.id} message={message} />
          ))}
        </div>
      </LoadingComponent>
    </div>
  );
};

export const MessageComponent = ({ message }) => {
  return (
    // <div className={"chat-message mb-3"}>
    //   <div
    //     className={
    //       `chat-message-content bg-white px-2 py-2 rounded border ` + border
    //     }
    //   >
    //     {message.message}
    //   </div>
    //   <small className="text-muted">{formatDate(message.created_at)}</small>
    // </div>
    <div key={message.id} className="bg-white mb-2 border rounded p-3">
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <p className="text-gray-600 mt-1">{message.message}</p>
          <div className="text-black-50 mt-2 text-sm text-gray-500">
            <small>{formatDate(message.created_at)}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

let MessageFormComponent = ({ onSend }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [formData, setFormData] = useState({
    message: "",
    recipients: "all",
  });
  const [users, setUsers] = useState([]);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);
  let [sending, setSending] = useState(false);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`updates/orgs`)
      .then((result) => {
        setUsers(result.users);
        setLoading(false);
        // setNotificationInterval();
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    init();
  }, []);

  let handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.message) {
      FireToast("Veuillez écrire votre message", "error");
      return;
    }

    if (formData.recipients !== "all" && selectedUsers.length <= 0) {
      FireToast("Sélectionnez les destinataires", "error");
      return;
    }

    setSending(true);
    Api.post(`updates`, { ...formData, selected: selectedUsers })
      .then((result) => {
        onSend(result.item);
        setFormData({ message: "", recipients: "all" });
        setSelectedUsers([]);
        setSending(false);
      })
      .catch((err) => {
        setSending(false);
        console.log("error", err);
        FireToast(
          "Une erreur est survenue, veuillez réessayer plus tard",
          "error"
        );
      });
  };

  const handleUserSelect = (userId) => {
    if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <LoadingComponent
      loading={loading}
      error={error}
      onRetry={init}
      isTable={false}
      hasData={true}
    >
      <div className="p-3">
        <div className="bg-white rounded-3 shadow-md p-4">
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <textarea
                className="w-full  form-control shadow-none p-2 border rounded"
                placeholder="Message d'annonce"
                rows="3"
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
                required
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2 font-medium">Destinataires</label>
              <div className="d-flex gap-4">
                <label className="flex items-center mx-2">
                  <input
                    type="radio"
                    name="recipients"
                    value="all"
                    checked={formData.recipients === "all"}
                    onChange={(e) =>
                      setFormData({ ...formData, recipients: e.target.value })
                    }
                    className="me-2"
                  />
                  Tous les utilisateurs
                </label>
                <label className="flex items-center mx-2">
                  <input
                    type="radio"
                    name="recipients"
                    value="selected"
                    checked={formData.recipients === "selected"}
                    onChange={(e) =>
                      setFormData({ ...formData, recipients: e.target.value })
                    }
                    className="me-2"
                  />
                  Organisation sélectionnée
                </label>
              </div>
            </div>

            {formData.recipients === "selected" && (
              <div className="mb-4">
                <div className="d-flex align-items-center mb-2">
                  <i className="fa fa-search me-2" />
                  <input
                    type="text"
                    className="w-full form-control p-2 border rounded"
                    placeholder="Rechercher des organisations..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <div className="max-h-48 overflow-y-auto border rounded p-2">
                  {filteredUsers.map((user) => (
                    <label
                      key={user.id}
                      className="text-nowrap d-inline-block align-items-center p-2 hover:bg-gray-50 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleUserSelect(user.id)}
                        className="me-3"
                      />
                      <span className="font-medium">{user.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            <button
              type="submit"
              disabled={sending}
              className="btn btn-primary text-white px-4 py-2 rounded d-flex align-items-center justify-content-center"
            >
              <i className="me-2 fa fa-plus" />
              Publier l'annonce
            </button>
          </form>
        </div>
      </div>
    </LoadingComponent>
  );
};
