import React, { useContext, useEffect, useState } from "react";
import { FooterComponent } from "../components/FooterComponent";
import { HeaderComponent } from "../components/HeaderComponent";

import { LoginPage } from "./LoginPage";
import { Outlet } from "react-router-dom";
import { UserContext } from "../store";
import { SidebarComponent } from "../components/SidebarComponent";

export const LayoutPage = () => {
  let { user, isAuthenticated } = useContext(UserContext);
  return (
    <>
      {isAuthenticated ? <SidebarComponent /> : <></>}
      <div className="wrapper d-flex flex-column min-vh-100">
        {isAuthenticated ? <HeaderComponent /> : <></>}
        <div className="body mb-3 rounded flex-grow-1 px-3">
          <div className="container-lg">
            {isAuthenticated ? <Outlet /> : <LoginPage />}
          </div>
        </div>
      </div>
      <FooterComponent />
    </>
  );
};
