import moment from "moment";
import Toastify from "toastify-js";

export const basename = "";
export const api_url = `/${basename}admin/api`;
export const FireToast = (text, className = 'success') => {
  Toastify({
    text: text,
    className: className,
    duration: 4000,
    gravity: "top",
    close: true,
    position: "center"
  }).showToast();
};

export const formatDate = (date) => moment(date).format('YYYY-MM-DD HH:mm');


let timeout;
export const clearDebounce = () => {
  clearTimeout(timeout);
}

export const debounce = (func, delay) => {
  return function (...args) {
    clearDebounce();
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
