import React, { useEffect, useState } from "react";

import CustomEditor from "../../../components/CustomEditor";
import sanitizeHtml from "sanitize-html";
import { Api } from "../../../api";
import { clearDebounce, debounce, FireToast } from "../../../config";
import { SectionWrapper } from "./SectionWrapper";
import Swal from "sweetalert2";
import { FileComponent } from "../../../components/FileComponent";
import { Uploader } from "../../../components/Uploader";

export const ReportAction = ({
  declaration,
  user,
  onUpdateDeclaration,
  files,
  setFiles,
}) => {
  let [newReport, setNewReport] = useState(declaration.analytics_result);
  let [saving, setSaving] = useState(false);
  let [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty) {
      debouncedSave(true);
    } else {
      clearDebounce();
    }
    return () => {
      clearDebounce();
    };
  }, [declaration, newReport, isDirty]);

  if (
    declaration.status === "submitted" ||
    !["analytics", "manager"].includes(user.role)
  ) {
    return <></>;
  }

  let sendForReview = async () => {
    let confirmation = await Swal.fire({
      title: "Êtes-vous sûr ?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    });
    if (!confirmation.isConfirmed) return;

    setSaving(true);

    try {
      let result = await Api.post(`declarations/${declaration.id}/review`, {
        report: newReport,
      });

      if (result.status === "ok") {
        FireToast("La déclaration a été envoyée pour révision.", "success");

        onUpdateDeclaration({
          ...declaration,
          status: result.declaration_status,
        });
        clearDebounce();
        setIsDirty(false);
      } else {
        FireToast(
          "Une erreur est survenue, l'opération n'a pas été effectuée ou un autre analyste a pris la déclaration.",
          "error"
        );
      }
    } catch (err) {
      console.log("error", err);
      FireToast(
        "Une erreur est survenue, veuillez réessayer plus tard.",
        "error"
      );
    }
    setSaving(false);
  };

  let saveReport = async (autoSave = false) => {
    setSaving(true);

    try {
      let result = await Api.post(
        `declarations/${declaration.id}/save_report`,
        {
          report: newReport,
        }
      );

      if (result.status === "ok") {
        if (!autoSave) {
          FireToast("Les données ont été enregistrées avec succès", "success");
        }
        setSaving(true);
        setIsDirty(false);
        clearDebounce();
      } else {
        if (!autoSave) {
          FireToast("Erreur Les données n'ont pas été enregistrées!", "error");
        }
      }
    } catch (err) {
      console.log("error", err);
      if (!autoSave) {
        FireToast(
          "Une erreur est survenue, veuillez réessayer plus tard.",
          "error"
        );
      }
    }
    setSaving(false);
  };

  const debouncedSave = debounce(saveReport, 6000);

  let onFileDeleted = (file) => {
    let item = files.find((item) => item.id === file.id);
    let index = files.indexOf(item);
    let oldFiles = [...files];
    if (index !== -1) {
      oldFiles.splice(index, 1);
    }
    setFiles([...oldFiles]);
  };

  let readOnly =
    user.id !== declaration.analytics_id ||
    !["process", "search"].includes(declaration.status);
  let myFiles = files.filter((f) => f.user_id === declaration.analytics_id);
  console.log("files", files);

  return (
    <SectionWrapper
      name="analytics-report"
      show={true}
      titleComponent={
        <div
          style={{ width: "auto" }}
          className="avatar avatar-xl justify-content-center align-items-center"
        >
          {/* <img
            className="avatar-img me-2"
            src={logo}
            style={{ width: "70px", maxWidth: 70, height: "auto" }}
          /> */}
          <span>{declaration.analytics_name} (Analyste)</span>
        </div>
      }
    >
      {readOnly ? (
        <div
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(newReport, {
              allowedTags: [
                "b",
                "i",
                "u",
                "ol",
                "ul",
                "br",
                "li",
                "strike",
                "div",
              ],
              allowedAttributes: {},
            }),
          }}
        ></div>
      ) : (
        <>
          <CustomEditor
            value={newReport}
            placeholder="Rédigez votre rapport..."
            onChange={(e) => {
              setIsDirty(true);
              setNewReport(e.target.value);
            }}
          />
          <Uploader
            readOnly={false}
            onFilesChanged={(newFiles) => {
              setFiles([...files, ...newFiles]);
              setIsDirty(true);
            }}
          />
        </>
      )}
      {!saving ? (
        <table className="table bg-white table-bordered table-condensed my-2">
          <tbody>
            {myFiles.map((file) => (
              <FileComponent
                key={file.id || file.name}
                defaultFile={file}
                declaration={declaration}
                onDeleted={onFileDeleted}
                readOnly={readOnly}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <></>
      )}
      {user.id === declaration.analytics_id &&
      ["process", "search"].includes(declaration.status) ? (
        <>
          <div className="d-flex mt-3 align-items-center justify-content-between">
            <div className="">
              <button
                className="btn btn-lg btn-info btn-block shadow-none"
                onClick={saveReport}
                disabled={saving}
              >
                Sauvegarder le brouillon
              </button>
            </div>
            <div className="">
              <button
                className="btn btn-lg btn-primary btn-block shadow-none"
                onClick={sendForReview}
                disabled={saving || !newReport}
              >
                Envoyer pour révision
              </button>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </SectionWrapper>
  );
};
