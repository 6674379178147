import moment from "moment";
import React from "react";
import html2pdf from "html2pdf.js";

export const DownloadPDFAction = ({ declaration, user }) => {
  const savePdf = () => {
    var collapses = window.document.querySelectorAll(".card-body.collapse");
    var elementInner = window.document.getElementById("element-to-prin-inner");
    elementInner.classList.add("scale-down-to-print");
    var element = window.document.getElementById("element-to-print");
    element.classList.add("scale-down-to-print");
    let elementsToHide = [];
    collapses.forEach((element) => {
      if (!element.classList.contains("show")) {
        element.classList.add("show");
        elementsToHide.push(element.id);
      }
    });
    console.log("elementsToHide", elementsToHide);
    var opt = {
      margin: [0, 0, 0, 0],
      filename: `UMEF - ${`${declaration.id}`.padStart(3, "0")}/${moment(
        declaration.created_at
      ).format("YYYY")}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1 },
      pagebreak: {
        mode: ["avoid-all", "css", "legacy"],
        after: ".section-break",
      },
    };
    var worker = html2pdf().set(opt).from(element).save();
    setTimeout(() => {
      elementInner.classList.remove("scale-down-to-print");
      element.classList.remove("scale-down-to-print");
      elementsToHide.forEach((id) => {
        window.document.getElementById(id).classList.remove("show");
      });
    }, 500);
  };

  if (user.role !== "analytics" || declaration.status !== "done") return <></>;

  return (
    <button
      id="download-declaration"
      onClick={savePdf}
      className="btn btn-primary mx-2"
    >
      Exporter en PDF
    </button>
  );
};
