import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";
import { TitleComponent } from "../../components/TitleComponent";
import { PaginationComponent } from "../../components/PaginationComponent";
import { Api } from "../../api";
import { users_type } from "../../data/data";
import { SearchComponent } from "../../components/SearchComponent";
import { CountryFlag } from "../../components/CountryFlag";
import { UserContext } from "../../store";

let interval;
export const UsersList = () => {
  let [users, setUsers] = useState([]);
  let [countryCodes, setCountryCodes] = useState([]);
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(true);
  let [query, setQuery] = useState("");
  let [pageCount, setPageCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);
  let [userType, setType] = useState("");
  let { user } = useContext(UserContext);

  let navigate = useNavigate();

  useEffect(() => {
    init();

    return () => {};
  }, [userType, currentPage]);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`users?q=${query}&page=${currentPage}&user_type=${userType}`)
      .then((result) => {
        setUsers(result.users);
        setCountryCodes(result.country_codes);
        setPageCount(result.total_pages);
        setError(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  let search = (e) => {
    setQuery(e.target.value);
    if (interval) {
      clearTimeout(interval);
    }
    interval = setTimeout(() => {
      init();
    }, 500);
  };

  let handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  return (
    <>
      <></>
      <TitleComponent
        text="Les utilisateurs"
        showBackButton={false}
        onActionClick={user.role === "admin" ? (e) => navigate("add") : null}
      />
      <div className="px-3">
        <div className="row">
          <div className="col-md-6 mb-3">
            <SearchComponent onChange={search} query={query} space={false} />
          </div>
          <div className="col-md-6 mb-3">
            <select
              className="form-select"
              id="user_type"
              name="user_type"
              value={userType}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Choisissez le type d'utilisateur</option>
              {Object.keys(users_type).map((item) => (
                <option key={item} value={item}>
                  {users_type[item]}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="px-2">
        <table className="table table-bordered table-striped table-sm">
          <thead className="table-primary">
            <tr>
              <th className="text-center">ID#</th>
              <th>Nom</th>
              <th>E-mail</th>
              <th>Type d'utilisateur</th>
              <th>Statut</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <LoadingComponent
              loading={loading}
              error={error}
              hasData={users.length > 0}
              onRetry={init}
              colSpanNumber={7}
            >
              {users.map((user) => {
                let codes = countryCodes.filter(
                  (item) => item.user_id === user.id
                );
                let icons = codes.map((code) => (
                  <CountryFlag
                    key={code.country_code}
                    countryCode={code.country_code}
                  />
                ));
                return (
                  <tr key={user.id}>
                    <td className="text-center">
                      {user.id}
                      {/* <Link to={`show/${user.id}`}>{user.id}</Link> */}
                    </td>
                    <td>
                      {icons} {user.name}
                    </td>
                    <td>{user.email}</td>
                    <td>{users_type[user.role]}</td>
                    <td>
                      {user.status == "active" ? (
                        <span className="badge bg-success">Activé</span>
                      ) : (
                        <span className="badge bg-secondary">Bloqué</span>
                      )}
                    </td>
                    <td className="text-center">
                      <Link
                        to={`/users/edit/${user.id}`}
                        className="btn btn-secondary btn-sm mx-1"
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                      <Link
                        to={`/users/show/${user.id}`}
                        className="btn btn-info btn-sm mx-1"
                      >
                        <i className="fa fa-eye" />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </LoadingComponent>
          </tbody>
        </table>
      </div>
      <PaginationComponent
        handlePageClick={handlePageClick}
        pageCount={pageCount}
      />
    </>
  );
};
