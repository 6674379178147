import React from "react";

export const LoadingComponent = ({
  loading,
  error,
  hasData = true,
  onRetry,
  isTable = true,
  colSpanNumber = 6,
  children,
}) => {
  if (hasData && !loading && !error) return children;

  return (
    <LoadingWrapper isTable={isTable} colSpanNumber={colSpanNumber}>
      {loading ? (
        <>
          <i className="fa fa-spin fa-spinner"></i>
          <span className="px-1">Chargement en cours...</span>
        </>
      ) : error ? (
        <div className="text-danger">
          <div className="mb-2">
            Une erreur est survenue, veuillez réessayer.
          </div>
          <button className="btn btn-primary" onClick={onRetry}>
            Réessayer
          </button>
        </div>
      ) : (
        <div className="text-dark text-center py-4 px-2 bg-white">
          Aucune donnée
        </div>
      )}
    </LoadingWrapper>
  );
};
let LoadingWrapper = ({ isTable = true, colSpanNumber, children }) => {
  if (isTable) {
    return (
      <tr>
        <td colSpan={colSpanNumber} className="p-3 text-center">
          {children}
        </td>
      </tr>
    );
  }
  return <div className="p-3 text-center">{children}</div>;
};
