import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingComponent } from "../../components/LoadingComponent";
import { TitleComponent } from "../../components/TitleComponent";
import { Api } from "../../api";
import { users_type } from "../../data/data";
import { UserSessions } from "./UserSessions";

export const ShowUser = () => {
  let [user, setUser] = useState({});
  let [sessions, setSessions] = useState([]);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    init();

    return () => {};
  }, []);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`users/${id}`)
      .then((result) => {
        setUser(result.user);
        setLoading(false);
        setSessions(result.user_sessions);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  let onLogout = (session) => {
    let item = sessions.find((item) => item.id === session.id);
    let index = sessions.indexOf(item);
    let oldSessions = [...sessions];
    if (index !== -1) {
      item.active = false;
      oldSessions[index] = item;
    }
    setSessions([...oldSessions]);
  };

  return (
    <>
      <TitleComponent text="Détails de l'utilisateur" />

      <LoadingComponent
        loading={loading}
        error={error}
        onRetry={init}
        isTable={false}
      >
        <div className="row">
          <div className="col-md-8 mx-auto">
            <div className="list-group my-2">
              <div className="list-group-item">
                <strong>Nom:</strong>
                <span className="px-1">{user.name}</span>
              </div>

              <div className="list-group-item">
                <strong>E-mail: </strong>
                <span className="px-1">{user.email}</span>
              </div>
              <div className="list-group-item">
                <strong>Type d'utilisateur:</strong>
                <span>{users_type[user.role]}</span>
              </div>
              <div className="list-group-item">
                <strong>Statut de l'utilisateur:</strong>
                <span className="px-1">
                  {user.status == "active" ? (
                    <span className="badge bg-success">Activer</span>
                  ) : (
                    <span className="badge bg-secondary">Bloquer</span>
                  )}
                </span>
              </div>
            </div>
            <UserSessions sessions={sessions} onLogout={onLogout} />
          </div>
        </div>
      </LoadingComponent>
    </>
  );
};
