import React, { useContext, useEffect, useState } from "react";
import { Api } from "../../../api";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { useParams } from "react-router-dom";
import { PaginationComponent } from "../../../components/PaginationComponent";
import { formatDate } from "../../../config";
import { fieldsTranslation } from "../../../data/data";
import { declarationStatusTranslation } from "../../../components/DeclarationStatusComponent";

export const DeclarationLogs = () => {
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(false);
  let [declaration, setDeclaration] = useState({});
  let [messages, setMessages] = useState([]);
  let [pageCount, setPageCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(0);

  let { id } = useParams();

  useEffect(() => {
    init();
  }, [pageCount, currentPage]);

  let init = () => {
    setLoading(true);
    setError(false);
    Api.get(`declarations/${id}/logs?page=${currentPage}`)
      .then((result) => {
        setDeclaration(result.declaration);
        setMessages(result.logs);
        setPageCount(result.total_pages);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        setError(true);
      });
  };

  let handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
  };

  let users = {
    [declaration.org_id]: declaration.org_name,
    [declaration.analytics_id]: declaration.analytics_name,
  };
  return (
    <div className="bg-white rounded-3">
      <div className="container pt-3">
        <h2 className="mb-2 mb-4">Journaux</h2>
        <div className="card" id="discussions">
          <div className="card-body">
            <div className="px-2">
              <table className="table table-bordered table-striped table-sm align-middle">
                <thead className="table-primary">
                  <tr>
                    <th>Nom</th>
                    <th>Action</th>
                    <th>Modifications</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <LoadingComponent
                    loading={loading}
                    error={error}
                    hasData={messages.length > 0}
                    onRetry={init}
                    colSpanNumber={7}
                  >
                    {messages.map((item) => (
                      <LogComponent key={item.id} log={item} users={users} />
                    ))}
                  </LoadingComponent>
                </tbody>
              </table>
            </div>
            <PaginationComponent
              handlePageClick={handlePageClick}
              pageCount={pageCount}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const LogComponent = ({ log, users }) => {
  switch (log.auditable_type) {
    case "Attachment":
      return <AttachmentLog users={users} log={log} />;
    case "Message":
      return <MessageLog users={users} log={log} />;
    case "Declaration":
      return <DeclarationLog users={users} log={log} />;
  }
  return <></>;
};

const AttachmentLog = ({ log, users }) => {
  return (
    <tr>
      <td>{users[log.user_id]}</td>
      <td>Envoyer un nouveau fichier</td>
      <td>ID: {log.auditable_id}</td>
      <td>{formatDate(log.created_at)}</td>
    </tr>
  );
};

const MessageLog = ({ log, users }) => {
  let commentText = "",
    changes = "";
  switch (log.action) {
    case "update":
      if (log.audited_changes["status"]) {
        commentText = `Message lu: ${log.auditable_id}`;
      }
      break;
    case "create":
      commentText = `Nouveau message: ${log.auditable_id}`;
      changes = `${log.audited_changes["content"]}`;
      break;
  }
  return (
    <tr>
      <td>{users[log.user_id]}</td>
      <td>{commentText}</td>
      <td>{changes}</td>
      <td>{formatDate(log.created_at)}</td>
    </tr>
  );
};

const DeclarationLog = ({ log, users }) => {
  let commentText = "",
    changes = "";
  switch (log.action) {
    case "update":
      if (log.audited_changes["status"]) {
        commentText = `Mettre à jour l'état de la déclaration: ${log.auditable_id}`;
        let [oldStatus, newStatus] = log.audited_changes["status"];
        changes = `From: ${declarationStatusTranslation[oldStatus]}\nTo: ${declarationStatusTranslation[newStatus]}`;
      } else {
        commentText = `Mise à jour de la déclaration: ${log.auditable_id}`;
        let translations = fieldsTranslation();
        changes = Object.keys(log.audited_changes).map((key) => {
          let [oldValue, newValue] = log.audited_changes[key];

          return `${translations[key]}:\n   From: ${oldValue}\n   To: ${newValue}`;
        });
        changes = changes.join("\n----\n");
      }
      break;
    case "create":
      commentText = `Nouvelle déclaration: ${log.auditable_id}`;
      break;
  }
  return (
    <tr>
      <td>{users[log.user_id]}</td>
      <td>{commentText}</td>
      <td style={{ whiteSpace: "pre-wrap" }}>{changes}</td>
      <td>{formatDate(log.created_at)}</td>
    </tr>
  );
};
