import React from "react";
import ReactPaginate from "react-paginate";

export const PaginationComponent = ({ handlePageClick, pageCount }) => {
  return (
    <ReactPaginate
      onPageChange={handlePageClick}
      pageCount={pageCount}
      containerClassName="pagination py-2 px-2 justify-content-center"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      previousClassName="page-item"
      nextClassName="page-item"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      disabledClassName="disabled"
      nextLabel="Suivant"
      previousLabel="Précédent"
    />
  );
};
