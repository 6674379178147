import React, { useEffect, useState } from "react";
import { Api } from "./api";
import { LoadingComponent } from "./components/LoadingComponent";
import { UserProvider } from "./store";
import { App } from "./App";
import { FireToast } from "./config";

export const AppStarer = () => {
  let [loading, setLoading] = useState(false);
  let [logginout, setLoggingout] = useState(false);
  let [error, setError] = useState(false);
  let [user, setUser] = useState(null);
  let [isAuthenticated, setIsAuthenticated] = useState(false);
  let [counter, setCounter] = useState({
    messages: [],
  });
  let token = localStorage.getItem("user_token");
  let intervalValue;

  useEffect(() => {
    if (token) {
      init();
    }
  }, []);

  let init = () => {
    setError(false);
    setLoading(true);
    Api.get("authentication/check")
      .then((result) => {
        if (result.status == "ok") {
          login(result.user);
          setCounter({
            messages: result.messages || [],
            tips: result.tips || 0,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  let setNotificationInterval = () => {
    clearNotificationInterval();
    if (process.env.NODE_ENV != "development") {
      intervalValue = setInterval(() => {
        Api.get("notifications_state")
          .then((result) => {
            setCounter({
              messages: result.messages || [],
            });
          })
          .catch((err) => {
            console.log("error fetching unread");
          });
      }, 15000);
    }
  };

  let clearNotificationInterval = () => {
    if (intervalValue) {
      clearInterval(intervalValue);
    }
  };

  let login = (user, token) => {
    if (token) {
      localStorage.setItem("user_token", token);
      init();
    }
    setUser(user);
    setIsAuthenticated(true);
    setNotificationInterval();
  };

  let logout = () => {
    setLoggingout(true);
    Api.get("authentication/logout")
      .then((result) => {
        if (result.status == "ok") {
          clearNotificationInterval();
          localStorage.removeItem("user_token");
          setUser(null);
          setIsAuthenticated(false);
        }
        setLoggingout(false);
      })
      .catch((err) => {
        FireToast(
          "Une erreur est survenue, veuillez réessayer plus tard.",
          "error"
        );
        setLoggingout(false);
      });
  };

  return (
    <LoadingComponent
      error={error}
      loading={loading}
      onRetry={init}
      isTable={false}
    >
      <UserProvider
        value={{
          user,
          isAuthenticated,
          logout,
          login,
          counter: counter,
          setCounter: setCounter,
          loading: loading,
          logginout: logginout,
        }}
      >
        <App />
      </UserProvider>
    </LoadingComponent>
  );
};
