import React, { useEffect, useState } from "react";
import { Api } from "../api";
import Swal from "sweetalert2";

export const FileComponent = ({
  defaultFile,
  declaration,
  readOnly = false,
  onDeleted,
}) => {
  let [file, setFile] = useState(defaultFile || {});

  useEffect(() => {
    if (!file.id) {
      upload();
    } else {
      init();
    }
  }, [defaultFile]);

  let init = () => {
    setFile({ ...file, loading: true, error: false });
    Api.get(`attachments/${file.id}`)
      .then((result) => {
        if (result.status !== "ok") {
          setFile({ ...file, loading: false, error: true });
        } else {
          setFile({ ...file, url: result.url, loading: false, error: false });
        }
      })
      .catch((err) => {
        setFile({ ...file, loading: false, error: true });
      });
  };

  let upload = () => {
    if (file.saving || !file.raw) return;
    console.log("uploading", file.name);

    // return;
    setFile({ ...file, saving: true, errorSaving: false });

    let endpoint = declaration?.id
      ? `declarations/${declaration.id}/attachments`
      : "attachments";

    let formData = new FormData();
    formData.append("image", file.raw, file.raw.name);

    Api.post(endpoint, formData, {
      "Content-Type": "multipart/form-data",
    })
      .then((result) => {
        if (result.status !== "ok") {
          setFile({ ...file, saving: false, errorSaving: true });
        } else {
          setFile({
            ...file,
            ...result.attachment,
            url: result.url,
            raw: null,
            saving: false,
          });
          defaultFile.id = result.attachment.id;
          // FireToast(result.msg);
        }
      })
      .catch((err) => {
        // FireToast("Une erreur est survenue, veuillez réessayer.", "error");
        setFile({ ...file, saving: false, errorSaving: true });
      });
  };

  let deleteFile = async () => {
    if (!file.id) return;

    let confirmation = await Swal.fire({
      title: "Êtes-vous sûr ?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    });
    if (!confirmation.isConfirmed) return;

    setFile({ ...file, deleting: true });

    Api.delete(`attachments/${file.id}`)
      .then((result) => {
        if (result.status !== "ok") {
          setFile({ ...file, deleting: false });
          FireToast("Une erreur est survenue, veuillez réessayer.", "error");
        } else {
          setFile({
            ...file,
            deleted: true,
            deleting: false,
          });
          // FireToast(result.msg);
          onDeleted(file);
        }
      })
      .catch((err) => {
        FireToast("Une erreur est survenue, veuillez réessayer.", "error");
        setFile({ ...file, deleting: false });
      });
  };

  let onFieldChanged = (keyName, value) => {
    setFile({
      ...file,
      [keyName]: value,
    });
  };

  // if (file.deleted) return <></>;

  return (
    <tr className="align-middle">
      <td>{file.id}</td>
      <td>
        {file.url ? (
          <a href={file.url} target="_blank" rel="noopener noreferrer">
            {file.name}
          </a>
        ) : (
          file.name
        )}
      </td>
      {isImageFile(file.name) && file.url ? (
        <td>
          <img src={file.url} width={50} />
        </td>
      ) : (
        <td></td>
      )}
      {readOnly ? (
        <></>
      ) : (
        <td>
          {file.loading || file.saving || file.deleting ? (
            "Traitement..."
          ) : file.error || file.errorSaving ? (
            <button
              className="btn btn-info btn-sm mx-1 my-1"
              onClick={file.error ? init : upload}
            >
              <i className="fa fa-refresh" />
            </button>
          ) : !file.submitted ? (
            <button
              className="btn btn-danger btn-sm mx-1 my-1"
              onClick={deleteFile}
            >
              <i className="fa fa-trash" />
            </button>
          ) : (
            <>
              <i className="fa fa-check text-success" />
              <span className="text-success mx-1">Soumis</span>
            </>
          )}
        </td>
      )}
    </tr>
  );
};

function isImageFile(filename) {
  const extension = filename.split(".").pop().toLowerCase();

  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

  return imageExtensions.includes(extension);
}
