import React from 'react'
import { Outlet } from 'react-router-dom'

export const UsersPage = () => {
    return (
        <div className='bg-white'>
            <Outlet />
        </div>
    )
}
