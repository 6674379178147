import React, { useEffect, useState } from "react";
import { Api } from "../api";
import { LoadingComponent } from "../components/LoadingComponent";
import { clearDebounce, debounce } from "../config";

export const SearchPage = () => {
  let [error, setError] = useState(false);
  let [loading, setLoading] = useState(false);
  let [searchBy, setSearchBy] = useState("persons");
  let [query, setQuery] = useState("");
  let [item, setItem] = useState(null);
  let [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty) {
      debouncedSave(true);
    } else {
      clearDebounce();
    }
    return () => {
      clearDebounce();
    };
  }, [query, searchBy, isDirty]);

  let search = () => {
    setLoading(true);
    setError(false);
    let url = `queries?query_by=${searchBy}&q=${query}`;
    Api.get(url)
      .then((result) => {
        setLoading(false);
        setItem(result.item);
        setIsDirty(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };

  let changeQuery = (newQuery) => {
    setIsDirty(false);
    // clearDebounce();
    setQuery(newQuery);
    if (!newQuery) {
      setItem(null);
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }
  };

  const debouncedSave = debounce(search, 1300);

  return (
    <div className="bg-white p-3 rounded">
      <h2 className="px-3 mb-3">Requête</h2>

      <ul className="nav nav-pills justify-content-center mb-5">
        <li className="nav-item mx-2">
          <button
            className={
              "nav-link btn- px-4 py-3 " +
              (searchBy === "persons" ? "active" : "border")
            }
            aria-current="page"
            onClick={(e) => {
              setSearchBy("persons");
              changeQuery("");
            }}
          >
            Personnes
          </button>
        </li>
        <li className="nav-item mx-2">
          <button
            className={
              "nav-link px-4 py-3 " +
              (searchBy === "companies" ? "active" : "border")
            }
            aria-current="page"
            onClick={(e) => {
              setSearchBy("companies");
              changeQuery("");
            }}
          >
            Entreprises
          </button>
        </li>
      </ul>

      <div className="col-md-8 mx-auto mb-4">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder={"Recherche..."}
          value={query}
          onChange={(e) => changeQuery(e.target.value)}
        />
      </div>

      <LoadingComponent
        loading={loading}
        error={error}
        hasData={
          (item != null && !Array.isArray(item)) ||
          (item != null && item.length > 0)
        }
        onRetry={search}
        isTable={false}
      >
        {!query || !item ? (
          <div></div>
        ) : searchBy === "companies" ? (
          item.map((company) => (
            <div
              className="card mx-auto border-0 my-2"
              style={{
                width: "50rem",
                borderRadius: "12px",
                background: "linear-gradient(135deg, #ff7e5f, #feb47b)",
                color: "white",
              }}
            >
              <div className="card-body p-4">
                <h5
                  className="card-title text-center mb-4"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Informations de l'entreprise
                </h5>

                <div style={{ fontSize: "1.2rem", lineHeight: "2" }}>
                  <div className="d-flex flex-column justify-content-between mb-2">
                    <span>Nom/Raison Sociale:</span>
                    <span>{company.name}</span>
                  </div>
                  <div className="d-flex flex-column justify-content-between mb-2">
                    <span>Prénom/Nom commercial:</span>
                    <span>{company.commerce_name}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Forme juridique:</span>
                    <span>{company.company_type}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Désignation de l'activité:</span>
                    <span>{company.sector}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Téléphone:</span>
                    <span>{company.numbers}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Date de création:</span>
                    <span>{company.city}</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>
            <div
              className="card border-0 mx-auto"
              style={{
                width: "50rem",
                borderRadius: "12px",
                background: "linear-gradient(135deg, #6b73ff, #000dff)",
                color: "white",
              }}
            >
              <div className="card-body p-4">
                <h4
                  className="card-title text-center mb-5"
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  }}
                >
                  Informations Personnelles
                </h4>

                <div style={{ fontSize: "1.2rem", lineHeight: "2" }}>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Nom:</span>
                    <span>{item.name_fr}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Nom AR:</span>
                    <span>{item.name_ar}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Date de naissance:</span>
                    <span>{item.birthdate}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Lieu de naissance:</span>
                    <span>{item.birthplace}</span>
                  </div>
                  <div className="d-flex justify-content-between mb-2">
                    <span>Genre:</span>
                    <span>{item.gender}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </LoadingComponent>
    </div>
  );
};

let company = {
  name: "La technologie pour tous SARL",
  number: "017774",
  nif: "RC0043",
  created_at: "01 Janvier 2023",
  owner_name: "Abdallah Muhammad Al-Amin",
  city: "Nouadhibou",
};
let person = {
  name: "Abdallah Muhammad Al-Amin Akah",
  birth_date: "12 janvier 1990",
  city: "Kiffa",
};
// La technologie pour tous SARL
// 017774
// RC0043
// 01 Janvier 2023
// Abdallah Muhammad Al-Amin
// Nouadhibou

// Abdallah Muhammad Al-Amin Akah
// 12 janvier 1990
// Kiffa
