import React, { useState } from "react";
import { FireToast } from "../../../config";
import { Api } from "../../../api";
import Swal from "sweetalert2";

export const AssignToMe = ({
  declaration,
  user,
  onUpdateDeclaration,
  onInit,
}) => {
  let [saving, setSaving] = useState(false);

  if (user.role !== "analytics" || declaration.status !== "submitted") {
    return <></>;
  }

  let submit = async () => {
    let confirmation = await Swal.fire({
      title: "Êtes-vous sûr ?",
      icon: "question",
      iconHtml: "?",
      confirmButtonText: "Oui",
      cancelButtonText: "No",
      showCancelButton: true,
      showCloseButton: true,
    });
    if (!confirmation.isConfirmed) return;

    setSaving(true);

    try {
      let result = await Api.get(`declarations/${declaration.id}/assign`);

      if (result.status === "ok") {
        FireToast(
          "La déclaration vous a été attribuée avec succès..",
          "success"
        );

        // onUpdateDeclaration({
        //   ...declaration,
        //   status: result.declaration_status,
        //   analytics_name: result.analytics_name,
        //   analytics_id: result.analytics_id,
        // });
        onInit();
      } else {
        FireToast(
          "Une erreur est survenue, l'opération n'a pas été effectuée ou un autre analyste a pris la déclaration.",
          "error"
        );
      }
    } catch (err) {
      console.log("error", err);
      FireToast(
        "Une erreur est survenue, veuillez réessayer plus tard.",
        "error"
      );
    }
    setSaving(false);
  };

  return (
    <button
      className="btn btn-lg text-white btn-info btn-block shadow-none mx-1"
      onClick={submit}
      disabled={saving}
    >
      Assigner à moi
    </button>
  );
};
